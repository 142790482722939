import { Button } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import DownloadingIcon from '@mui/icons-material/Downloading';

const Subheading = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
`;
const Heading = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 120%;
  text-transform: uppercase;
`;
const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  font-weight: 300;
  font-family: var(--text-regular-light);
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--heading-desktop-h2-size);
  font-family: var(--heading-desktop-h4);
`;
const SectionTitle = styled.div`
  width: 48rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const Textp = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
`;
const Button1 = styled.div`
  border: 1px solid var(--border-primary);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  font-size: var(--text-medium-light-size);
`;
const Title = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const PlaceholderImageIcon = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 18.75rem;
  flex-shrink: 0;
  object-fit: cover;
`;
const Text2 = styled.div`
  position: relative;
  line-height: 150%;
  font-weight: 600;
`;
const Container = styled.div`
  background-color: var(--color-neutral-neutral-lightest);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) var(--padding-5xs);
`;
const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const Heading1 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 140%;
  text-transform: uppercase;
`;
const Text3 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-regular-light-size);
  line-height: 150%;
  font-family: var(--text-regular-light);
`;
const Content1 = styled.div`
  // width: 26rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--heading-desktop-h5-size);
  font-family: var(--heading-desktop-h4);
`;
const Content2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const PlaceholderImageParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const IconChevronRight = styled.img`
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
`;
const ButtonmuitextIcon = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-xl);
  gap: var(--gap-5xs);
  font-size: var(--text-regular-light-size);
  color: var(--primary-main);
`;
const Card = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-11xl);
  min-width: 300px;
`;
const Card1 = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;
const Row = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  font-size: var(--text-small-normal-size);
    flex-wrap: wrap;

`;
const BlogRoot = styled.section`
  align-self: stretch;
  background-color: var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  gap: var(--gap-61xl);
  text-align: left;
  font-size: var(--text-regular-light-size);
  color: var(--border-primary);
  font-family: var(--text-regular-light);
`;

const DownloadsSection = () => {
  return (
    <BlogRoot>
      <Title>
        <SectionTitle>
          <Subheading>Updates</Subheading>
          <Content>
            <Heading>Project Development Updates</Heading>
            <Text1>Stay informed about the latest developments.</Text1>
          </Content>
        </SectionTitle>
        <Button1>
          <Textp>View all</Textp>
        </Button1>
      </Title>
      <Row>
        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/IDMAnnualReportCoverMock.jpg" />
            <Content2>
              <Info>
                <Container>
                  <Text2>REPORT</Text2>
                </Container>
                <Text2>July 2024</Text2>
              </Info>
              <Content1>
                <Heading1>ANNUAL REPORT FOR THE YEAR ENDED 31 DECEMBER 2023</Heading1>
                <Text3>
                 Your directors submit their Annual Report for IDM International Limited and its controlled entities for the year ended 31 December 2023
                </Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
          <ButtonmuitextIcon variant="outlined" href='/IDM-International-Ltd-December2023Report.pdf' download endIcon={<DownloadingIcon />} >
            Download
          </ButtonmuitextIcon>
        </Card>
        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/AGMNoticeCover.jpg" />
            <Content2>
              <Info>
                <Container>
                  <Text2>NOTICE</Text2>
                </Container>
                <Text2>July 2024</Text2>
              </Info>
              <Content1>
                <Heading1>ANNUAL GENERAL MEETING OF SHAREHOLDERS</Heading1>
                <Text3>
                 IDM International Limited will be holding its Annual General Meeting at 10.30am (Perth time) on 16th August 2024 at Level 1, 33 Ord Street, West Perth Western Australia 6005.
                </Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
          <ButtonmuitextIcon variant="outlined" href='/IDM-Letter-NoticeOf2024AGM_JWS.pdf' download endIcon={<DownloadingIcon />} >
            Download
          </ButtonmuitextIcon>
        </Card>


        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/IDM-Update-Cover-June-2024.jpg" />
            <Content2>
              <Info>
                <Container>
                  <Text2>UPDATE</Text2>
                </Container>
                <Text2>June 2024</Text2>
              </Info>
              <Content1>
                <Heading1>Corporate Update</Heading1>
                <Text3>
                  IDM International Limited is pleased to provide an update on
                  the operations at its Mankayan copper-gold project in the
                  Philippines.
                </Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
          <ButtonmuitextIcon variant="outlined" href='/IDM-International-Corporate-Update20240628.pdf' download endIcon={<DownloadingIcon />} >
            Download
          </ButtonmuitextIcon>
        </Card>
        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/placeholder-image13@2x.png" />
            <Content2>
              <Info>
                <Container>
                  <Text2>UPDATE</Text2>
                </Container>
                <Text2>May 2024</Text2>
              </Info>
              <Content1>
                <Heading1>Corporate Update</Heading1>
                <Text3>
                  IDM International Limited is pleased to provide an update on
                  the operations at its Mankayan copper-gold project in the
                  Philippines.
                </Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
          <ButtonmuitextIcon variant="outlined" href='/IDM-ShareholderUpdate23-05-24.pdf' download endIcon={<DownloadingIcon />} >
            Download
          </ButtonmuitextIcon>
        </Card>
        
        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/placeholder-image38@2x.png" />
            <Content2>
              <Info>
                <Container>
                  <Text2>UPDATE</Text2>
                </Container>
                <Text2>September 2023</Text2>
              </Info>
              <Content1>
                <Heading1>CORPORATE PRESENTATION</Heading1>
                <Text3>MANKAYAN COPPER-GOLD PORPHYRY PROJECT</Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
         <ButtonmuitextIcon variant="outlined" href='/IDM-CorporatePresentationMay2024.pdf' download endIcon={<DownloadingIcon />} >
            Download
          </ButtonmuitextIcon>
        </Card>
        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/placeholder-image14@2x.png" />
            <Content2>
              <Info>
                <Container>
                  <Text2>REPORT</Text2>
                </Container>
                <Text2>27 December 2022</Text2>
              </Info>
              <Content1>
                <Heading1>Independent Technical Assessment</Heading1>
                <Text3>
                  Independent Technical Assessment and Valuation Report for
                  Mankayan Copper-Gold project, Philippines
                </Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
          <ButtonmuitextIcon variant="outlined" href='/PH-CSL-IDM01-IER_Val27Dec2022.pdf' download endIcon={<DownloadingIcon />} >
            Download
          </ButtonmuitextIcon>
        </Card>
      </Row>
    </BlogRoot>
  );
};

export default DownloadsSection;
